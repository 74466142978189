import * as React from "react"
import Layout from '../components/layout';

// markup
const IndexPage = () => {
  return (
    <Layout title="Contact">
            <iframe title="contact" src="https://docs.google.com/forms/d/e/1FAIpQLScHD5fDrsYsAvI2hRNTnL1ZcLAeGeUdsOQCLiylFEngc-R5mA/viewform?embedded=true" width="100%" height="900" frameborder="0" marginheight="0" marginwidth="0">Laden…</iframe>    
    </Layout>
  )
}

export default IndexPage
